/* styles.scss */

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
}

.app {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.main-heading {
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 2rem;
  color: #333;
}

.main-nav {
  text-align: center;
  margin-bottom: 2rem;

  ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }

  li {
    display: inline-block;
  }

  a {
    text-decoration: none;
    color: #007bff;
    font-size: 1.2rem;
    padding: 0.5rem 1rem;
    border-radius: 5px;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #e6f2ff;
    }
  }
}

h2 {
  color: #444;
  border-bottom: 2px solid #007bff;
  padding-bottom: 0.5rem;
}

p {
  text-align: justify;
}

.books-page {
  h2 {
    color: #444;
    border-bottom: 2px solid #007bff;
    padding-bottom: 0.5rem;
    margin-bottom: 2rem;
  }

  .year-section {
    margin-bottom: 2rem;

    h3 {
      color: #007bff;
      margin-bottom: 1rem;
    }

    ul {
      list-style-type: none;
      padding-left: 0;
    }

    li {
      margin-bottom: 0.5rem;
      font-size: 1rem;
      line-height: 1.4;

      .book-note {
        font-style: italic;
        color: #666;
        font-size: 0.9em;
      }
    }
  }
}
